.bsp {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    gap: 12px;
    font-size: 12pt;
}

.bsp_dot {
    background-color: grey;
    border-radius: 6px;
    width: 12px;
    height: 12px;
    min-width: 12px;
}

.bsp p {
    margin: 0;
}

.bsp .monospace {
    font-size: smaller;
    font-family: monospace;
}