h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  @apply font-bold text-slate-950
}

h1, .h1 {
  @apply text-4xl;
}

h2, .h2 {
  @apply text-3xl;
}

h3, .h3 {
  @apply text-2xl;
}

h4, .h4 {
  @apply text-xl;
}

h5, .h5 {
  @apply text-lg;
}

h6, .h6 {
  @apply text-base;
}

p {
  margin: 0 0 1em;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}