[data-sticky-td="true"].table-tbody-td {
  background: white !important;
}

[data-sticky-td="true"].table-tbody-td:hover {
  background: var(--color-neutral-50) !important;
}

.ReactTable-container {
  border: 1px solid #ddd;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  max-width: 100%;
  overflow: auto;
}

.ReactTable-container .ReactTable {
  /* border: 1px solid #ddd; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow: auto;
  /* overflow: scroll; */
  max-width: 100%;
}

.ReactTable-container .ReactTable.sticky {
  overflow: auto;
}

.ReactTable-container .ReactTable-content {
  position: relative;
  flex: 1 1 0%;
  z-index: 0;
}

.ReactTable-container .ReactTable-interior {
  position: relative;
  width: 100%;
  overflow: auto;
  will-change: transform;
  direction: ltr;
}

.ReactTable-container .ReactTable .tr .th {
  background-color: var(--color-neutral-50) !important;
}

.ReactTable-container .ReactTable .tr .td {
  background-color: transparent;
}

.ReactTable-container .ReactTable .tr:hover .td {
  background-color: var(--color-neutral-50) !important;
}

.ReactTable-container .ReactTable .tr:last-child:not(:only-child) .td {
  border-bottom: 0;
}

.ReactTable-container .ReactTable.sticky .th,
.ReactTable-container .ReactTable.sticky .td {
  /* padding: 5px; */
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: #fff;
}

.ReactTable-container .ReactTable.sticky .th {
  overflow: hidden;
}

.ReactTable-container .ReactTable.sticky .th:last-child,
.ReactTable-container .ReactTable.sticky .td:last-child {
  border-right: 0;
}

.ReactTable-container .ReactTable.sticky .ReactTable-thead,
.ReactTable-container .ReactTable.sticky .ReactTable-tfoot {
  position: sticky;
  z-index: 1;
  width: fit-content;
}

.ReactTable-container .ReactTable.sticky .header,
.ReactTable-container .ReactTable.sticky .footer {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.ReactTable-container .ReactTable.sticky .header {
  z-index: 4;
  top: 0;
  box-shadow: 0px 3px 3px #ccc;
}

.ReactTable-container .ReactTable.sticky .footer {
  bottom: 0;
  box-shadow: 0px -3px 3px #ccc;
}

.ReactTable-container .ReactTable.sticky .body {
  position: relative;
  z-index: 0;
}

.ReactTable-container .ReactTable.sticky [data-sticky-td] {
  position: sticky;
}

.ReactTable-container .ReactTable.sticky [data-sticky-last-left-td] {
  box-shadow: 2px 0px 3px #ccc;
}

.ReactTable-container .ReactTable.sticky [data-sticky-first-right-td] {
  box-shadow: -2px 0px 3px #ccc;
}

/* Actions Cell Dropdown Menu */
.dropdown .menu.ActionsCell-menu {
  border: 0 !important;
  width: 150px !important;
  min-width: unset !important;
}
