.ui.modal {
  position: fixed;
  padding: 40px;
  width: auto;
  height: fit-content;
  max-width: 680px;
  border-radius: 5px;
}

.ui.modal.global-modal {
  left: 50%;
  /* max-height: calc(85vh - 10rem); */
  overflow-y: auto;
  top: 10vh;
  transform: translateX(-50%);
}

.ui.modal.center-modal {
  top: 40%;
}

.ui.modal .header {
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 24px;
  height: 30px;
}

.ui.modal .header span {
  color: var(--color-slate-950);
  font-size: 24px;
  line-height: 1.25;
  font-weight: 700;
}

.ui.modal .actions {
  background: none;
  border-top: 0;
  margin-top: 16px;
  padding: 0;
}

.ui.modal .actions .button:first-child {
  margin: 0px 16px 0px 0px;
}

.ui.modal .actions .button:last-child {
  margin: 0px;
}

.ui.modal .content {
    padding: 0px;
}

.ui.modal .content .scrolling {
  max-height: calc(80vh - 10rem);
  overflow: auto;
}

.ui.modal .description,
.ui.modal p {
  line-height: 1.57rem;
  font-size: 14px;
  color: var(--color-slate-950);
}

.ui.modal .description .ui.input,
.ui.modal p .ui.input {
  /* This value is 49 because the bottom element has a 25px margin top and we need a total of 64px of space between them
  Did this approach because the bottom element needs that 25px margin top to match another modal design */
  margin-bottom: 48px;
  width: 100%;
}

.ui.modal .table thead th {
  border-bottom-width: 2px;
  padding-bottom: 15px;
}

.ui.modal tbody {
  font-size: 14px;
}

.ui.modal tbody > tr > td {
  padding-bottom: 1.5em;
  padding-top: 1.5em;
}

.ui.modal.aligned.right {
  height: 100%;
  left: auto;
  max-height: initial;
  right: 0;
  top: 0;
  width: 500px;
}

.ui.modal.aligned.right .content {
  max-height: initial;
}

.ui.modal .ui.form .field > label {
  font-size: 1rem;
}

.ui.modal .page-btns {
  padding: initial;
}

.ui.modal .institution-selection-dropdown {
  border-radius: 0;
}

.ui.dimmer {
  background-color: rgb(23, 35, 58, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

/* "fPUZnN" this is bad and should be replaced with a real className */
.body.dimmed.dimmable .fPUZnN {
  overflow: hidden;
}

.institution-selection-icon.icon-container {
  display: flex;
  align-items: center;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px;
}
