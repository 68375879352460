.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-style: normal; */
  /* font-weight: 600; */
  /* line-height: 19px; */
  /* text-align: center; */
  /* color: $gray-dark-text; */
  width: 100%;
  /* padding-top: 15px; */
  @apply text-sm mt-3 text-neutral-600 font-semibold text-center;
}
.pagination * { 
  height: auto !important;
  /* @apply !text-sm; */
}

.pagination .pagination-menu {
  display: inline-flex;
  align-items: center;
  margin: 0;
  min-height: auto !important;
  box-shadow: none;

  @apply text-neutral-600;
}

.pagination .pagination-menu .item {
  justify-content: center;

  @apply text-sm font-semibold py-2.5;
}

.pagination .pagination-menu .item:before {
  content: none !important;
}

.pagination .pagination-menu .item.active {
  @apply bg-neutral-300;
}

.pagination .pagination-menu .item.results {
  @apply text-neutral-400 border-l border-neutral-300;
}

.pagination .pagination-options {
  display: inline-flex;
  align-items: center;
  /* border-radius: 5px; */
  /* border: 1px solid $neutral-gray; */
  /* padding: 0 1rem; */

  @apply border border-neutral-300 rounded-md !mt-0;
}

.pagination .pagination-options .item {
  @apply !text-sm font-semibold !py-2.5;
}

.pagination .pagination-options .item.label {
  margin: 0 !important;
  @apply !px-3.5;
}

.pagination .pagination-options .selection.dropdown {
  margin: 0 !important;
  min-width: 0;
  border-radius: 0;

  @apply border-x border-y-0 border-neutral-300 !pr-10;
}

.pagination .pagination-options .selection.dropdown > .icon {
  @apply !text-sm !p-0;
}

.pagination .pagination-options .selection.dropdown .active {
  box-shadow: none;
}

.pagination .pagination-options .selection.dropdown .menu {
  /* border: 1px solid $neutral-gray; */
  /* margin-top: 1px; */

  @apply border border-neutral-300;
}

.pagination .pagination-options .selection.dropdown .menu .item {
  border: none;
}

.pagination .pagination-options .selection.dropdown .menu .item:hover {
  /* background-color: $pale-gray; */
  @apply bg-neutral-100
}
