/******************************************************************************
 buttons
******************************************************************************/

.tickler-action-button:hover {
  background-color: #e1e4ea;
}

/******************************************************************************
 icons
******************************************************************************/
.ui.menu .item > i.commotion.disabled.icon {
  opacity: 1;
}

.commotion.ui.circular.button > i.icon {
  height: 1rem;
  line-height: 1rem;
  width: 1rem;
}

i.icon.green {
  color: #01a49c; /* turquoise */
}

/******************************************************************************
 checkbox toggle
******************************************************************************/
.ui.toggle.checkbox label:before {
  z-index: initial; /* setting this causes problems with visibility in other elements and is unnecessary for the toggle appearance */
}

.ui.toggle.checkbox label:after {
  z-index: initial; /* setting this causes problems with visibility in other elements and is unnecessary for the toggle appearance */
}

/******************************************************************************
 Documents
******************************************************************************/
.ui.header.document-header {
  background-color: #e1e4ea;
  padding: 3px 5px;
  margin: 1rem 0;
}

.document-section {
  margin-bottom: 3rem;
}

/******************************************************************************
 LOS Filters
******************************************************************************/
.LOS .ui.selection.dropdown {
  width: 100%;
}

.fullscreen.message {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.global-header > .global-header-top .user-settings .menu.transition.floating {
  left: inherit
}

.doc-tracking-tickler-table-col {
  width: 22px !important;
  padding: 0 !important;
}

/******************************************************************************
  Dropdown
******************************************************************************/

.dropdown .menu {
  min-width: 265px !important;
}

.dropdown .search.input {
  width: 100%;
}
