.setup-text {
  font-size: 14px;
  font-weight: normal;
  color: #0b1321;
}

.bp-link-text {
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0px 8px 0px;
}

.entity-info-text {
  font-size: 16px;
  color: #747474;
}

.bp-links-search-description {
  margin: 5px 0px 15px 0px;
  font-weight: bold;
  font-size: 14px;
}

.bp-links-search-info {
  color: #747474;
  margin: 10px;
  align-items: left;
}

.dropdown-header {
  font-size: 16px;
}

.ui.grid > .row > .column.link-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.BorrowerPortalLinks-AddExisting {
  display: flex !important;
}
.BorrowerPortalLinks-AddExisting > button {
  margin: 0 !important;
}

.BorrowerPortalLinks-AddExisting .menu {
  margin-top: 0 !important;
  left: auto !important;
  right: 0 !important;
}

.BorrowerPortalLinks-AddExisting .menu > .item.item-custom {
  padding: 1rem !important;
  height: unset !important;
}