.Note {
  display: flex;
}

.Note p:last-child {
  margin-bottom: 0;
}

.Note .MessageAvatar {
  border-radius: 100%;
  font-size: 1.25rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}