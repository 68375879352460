/* Figma : Colors
 * https://www.figma.com/file/mCVE9nD0K09i0RqpFGPzJy/Tailwind-Colors?type=whiteboard&node-id=0-1&t=zHxZU5wpWavnwAEB-0
*/

:root {
  --color-white: #fff;
  --color-slate-extended-50: #f3f4f7;  /* paleMist2 */
  --color-slate-extended-100: #edeff2; /* paleMist3 */
  --color-slate-extended-200: #e7e9ee; /* paleMist4 */

  --color-slate-50: #f9fafb;           /* paleMist1 */
  --color-slate-100: #edeff3;
  --color-slate-200: #e1e4ea;          /* @blueMist $blue-mist */
  --color-slate-300: #9faabd;          /* @blueMirror $blue-mirror */
  --color-slate-400: #7994a7;          /* rainCloud */
  --color-slate-500: #43657e;          /* puddle */
  --color-slate-600: #40577c;          /* @pond $pond $blue */
  --color-slate-700: #334663;          /* @blueNightPond $dark-blue */
  --color-slate-800: #17233a;          /* @blueNavy $blue-navy */
  --color-slate-900: #111b2e;
  --color-slate-950: #0b1321;          /* @blueMidnight $blue-midnight $black */

  --color-neutral-50: #f9f9f9;         /* @neutralPaleGray1 $neutralPaleGray1 $pale-gray */
  --color-neutral-100: #f5f5f5;        /* @neutralPaleGray2 $neutralPaleGray2 */
  --color-neutral-200: #ededed;        /* @neutralLightGray $gray-background */
  --color-neutral-300: #dedede;        /* @neutralGray $neutral-gray */
  --color-neutral-400: #b1b1b1;        /* @neutralFrenchGray $gray-icon */
  --color-neutral-500: #747474;        /* @grey @chalkBoard $grey $chalk-board $gray-text */
  --color-neutral-600: #4a4a4a;        /* @neutralSlate $neutral-slate $gray-dark-text */
  --color-neutral-700: #3b3b3b;
  --color-neutral-800: #2c2c2c;
  --color-neutral-900: #1e1e1e;
  --color-neutral-950: #0f0f0f;

  --color-red-50: #fff2ef;             /* @paleRed */
  --color-red-100: #fcd5c6;
  --color-red-200: #fab89d;
  --color-red-300: #f79b73;
  --color-red-400: #f47e4a;            /* @salmonPrint $salmon-print */
  --color-red-500: #e36730;
  --color-red-600: #d24f15;            /* @rust $rust $red (rustPrint#d35328) */
  --color-red-700: #c44e1a;            /* mediumDarkRust */
  --color-red-800: #993811;            /* darkRust */
  --color-red-900: #7a2d0e;
  --color-red-950: #5c220a;

  --color-orange-50: #fff6ed;
  --color-orange-100: #ffecdb;         /* paleOrange */
  --color-orange-200: #fdd6b2;
  --color-orange-300: #fcc089;
  --color-orange-400: #faaa60;         /* lightOrange */
  --color-orange-500: #f98f2e;         /* $orange */
  --color-orange-600: #d57723;
  --color-orange-700: #b15f17;         /* mediumDarkOrange */
  --color-orange-800: #824819;         /* darkOrange */
  --color-orange-900: #683a14;
  --color-orange-950: #4e2b0f;

  --color-yellow-50: #f7f5f2;          /* cream */
  --color-yellow-100: #fef7e6;         /* @paleYellow $pale-yellow */
  --color-yellow-200: #fdeabc;
  --color-yellow-300: #fcdc92;         /* lightYellow */
  --color-yellow-400: #fbcc60;
  --color-yellow-500: #f9bc2e;         /* @yellow $yellow */
  --color-yellow-600: #bc8505;         /* @lightBrown $light-brown */
  --color-yellow-700: #996c07;         /* mediumDarkYellow */
  --color-yellow-800: #705216;         /* darkYellow */
  --color-yellow-900: #5b4105;         /* $brown */
  --color-yellow-950: #443104;

  --color-lime-50: #f4fae7;
  --color-lime-100: #eaf5ce;           /* paleOlive */
  --color-lime-200: #dcefab;
  --color-lime-300: #cfe888;
  --color-lime-400: #c1e265;           /* lightOlive */
  --color-lime-500: #a5cc39;
  --color-lime-600: #8ab60c;           /* $olive */
  --color-lime-700: #618100;           /* mediumDarkOlive */
  --color-lime-800: #4d6007;           /* darkOlive */
  --color-lime-900: #3e4d06;
  --color-lime-950: #2e3a04;

  --color-teal-50: #f3fbfb;
  --color-teal-100: #e6f6f6;           /* @paleAqua $pale-aqua */
  --color-teal-200: #bdeae6;
  --color-teal-300: #94ddd5;
  --color-teal-400: #6bd1c5;           /* aqua */
  --color-teal-500: #36bbb1;
  --color-teal-600: #01a49c;           /* @turquoise $green */
  --color-teal-700: #00827c;           /* mediumDarkTurquoise */
  --color-teal-800: #015952;           /* darkTurquoise */
  --color-teal-900: #014742;
  --color-teal-950: #013531;

  --color-cyan-50: #f0f9fd;
  --color-cyan-100: #e1f2fa;           /* @paleTeal $pale-teal */
  --color-cyan-200: #c3e3f2;
  --color-cyan-300: #a5d4ea;
  --color-cyan-400: #87c6e1;
  --color-cyan-500: #69b7d9;           /* @lightTeal $light-teal */
  --color-cyan-600: #1d81ab;           /* @teal $teal */
  --color-cyan-700: #186c8c;           /* mediumDarkTeal */
  --color-cyan-800: #14566d;           /* darkTeal */
  --color-cyan-900: #104557;
  --color-cyan-950: #0c3441;

  --color-violet-50: #f2effc;
  --color-violet-100: #e5def8;
  --color-violet-200: #d8cef5;         /* paleViolet */
  --color-violet-300: #b5a9da;
  --color-violet-400: #9283bf;         /* lightViolet */
  --color-violet-500: #7867a8;
  --color-violet-600: #5d4b91;         /* $violet */
  --color-violet-700: #524483;         /* mediumDarkViolet */
  --color-violet-800: #483d75;         /* darkViolet */
  --color-violet-900: #3a315e;
  --color-violet-950: #2b2546;

  --color-purple-50: #f8effb;
  --color-purple-100: #f2def6;
  --color-purple-200: #ebcef2;         /* palePurple */
  --color-purple-300: #d3a6de;
  --color-purple-400: #ba7dca;         /* lightPurple */
  --color-purple-500: #b069c2;
  --color-purple-600: #a555b9;         /* $purple  */
  --color-purple-700: #874697;         /* mediumDarkPurple */
  --color-purple-800: #693775;         /* darkPurple */
  --color-purple-900: #542c5e;
  --color-purple-950: #3f2146;

  --color-fuchsia-50: #fff1fe;
  --color-fuchsia-100: #ffe3fc;        /* palePink */
  --color-fuchsia-200: #f7cef3;
  --color-fuchsia-300: #efb9e9;        /* lightPink */
  --color-fuchsia-400: #e590dc;
  --color-fuchsia-500: #db66cf;        /* $pink */
  --color-fuchsia-600: #b052a8;        /* mediumDarkPink */
  --color-fuchsia-700: #9a4894;
  --color-fuchsia-800: #843e81;        /* darkPink */
  --color-fuchsia-900: #6a3267;
  --color-fuchsia-950: #4f254d;

  /* TBD */
  --color-navy-alpha-60: rgba(23, 35, 58, 0.6); /* --color-slate-800 */
  --color-mirror-alpha-66: rgba(159, 170, 189, 0.66);
  --color-mirror-alpha-33: rgba(159, 170, 189, 0.33);

  --padding-small: 0.5rem;
  --padding-medium: 1rem;
  --padding-large: 1.5rem;
}
