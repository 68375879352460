  /*
   * Variants: mapping
   * design system name | fomantic class
   *        ---         |      ---
   * primary (default)  | primary
   * secondary          | basic
   * tertiary           | tertiary
   */

.ui.button, .btn {
  align-items: center;
  box-shadow: none !important;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  height: initial;
  text-decoration: none;
  text-wrap: nowrap;
  outline: none;
  user-select: none;
  will-change: auto;
  @apply rounded-md px-4 py-2 text-sm font-semibold;
  @apply border transition-all ease-in duration-75;
  @apply focus:ring-4 focus:outline-none;
}

.btn.disabled,
.btn:disabled,
.btn.disabled:hover,
.btn:disabled:hover {
  cursor: default;
  opacity: 0.5 !important;
  background-image: none;
  box-shadow: none;
  pointer-events: none !important;
}

.ui.button {
  /* Note: these 4 lines can be removed after all Buttons have been refactored */
  @apply text-white;
  @apply border-slate-600 hover:border-slate-700 active:border-slate-800;
  @apply bg-slate-600 hover:bg-slate-700 active:bg-slate-800;
  @apply focus:ring-slate-300;
}

/* Primary (https://zpl.io/wqMO4Yn) */
.btn-primary {
  @apply text-white;
  @apply border-slate-600 hover:border-slate-700 active:border-slate-800;
  @apply bg-slate-600 hover:bg-slate-700 active:bg-slate-800;
  @apply focus:ring-slate-300;
}

.btn-primary:disabled {
  cursor: default !important;
  @apply border-slate-400 hover:border-slate-400 active:border-slate-400;
  @apply bg-slate-400 hover:bg-slate-400 active:bg-slate-400;
}

/* Secondary (https://zpl.io/7wqPo4K) */
.ui.button.basic, .btn-secondary {
  @apply text-neutral-600;
  @apply border-neutral-300 hover:border-neutral-300 active:border-neutral-300;
  @apply bg-white hover:bg-neutral-100 active:bg-neutral-300;
  @apply focus:ring-neutral-100;
}

/* Tertiary (https://zpl.io/DlxWBX3) */
.ui.button.tertiary, .btn-tertiary {
  border-color: transparent;
  @apply text-cyan-600 hover:text-cyan-700 hover:underline;
  @apply hover:border-neutral-100 focus:border-neutral-100 active:border-neutral-200;
  @apply bg-none hover:bg-neutral-100 focus:bg-neutral-100 active:bg-neutral-200;
  @apply focus:ring-neutral-100;
}

/*******************************
            Size
*******************************/

.ui.button.tiny, .ui.button.small, .btn-sm {
  @apply px-2 py-1 !important;
}

.btn-sm {
  @apply px-3 !important;
}

.ui.button.medium, .btn-md {
  /* May not be needed */
  @apply px-4 py-2 !important;
}

.ui.button.large, .btn-lg {
  @apply px-5 py-3.5 !important;
}

.ui.button.fill {
  width: 100%;
}

/*******************************
          Variants?
*******************************/

.ui.button.circular {
  height: inherit;
}

.ui.button.inverted {
  @apply text-neutral-600 !important;
}

.ui.button.inverted:hover {
  @apply text-slate-950 !important;
}

/*******************************
          Color Buttons
*******************************/

.ui.button.red {
  @apply text-white bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 !important;
}

.ui.button.orange {
  @apply text-white bg-orange-500 hover:bg-orange-600 border-orange-500 hover:border-orange-600 !important;
}

/*******************************
          Icon Only
*******************************/

.ui.animated.icon.button > .content > .icon,
.ui.icon.buttons .button > .icon,
.ui.icon.button > .icon {
  opacity: 1;
  margin: 0 !important;
}

.ui.animated.button > .content > .icon {
  vertical-align: top;
}

/*******************************
          Labeled w/Icon
*******************************/
.ui.labeled.icon.button {
  display: flex;
  align-items: center;
}

.ui.labeled.icon.button > .icon {
  height: initial;
  margin-right: 10px !important;
  padding: 0;
  position: initial;
}

.ui.labeled.icon.button > .icon:before {
  position: initial;
}
.ui.labeled.icon.button > .icon.right {
  margin-left: 10px !important;
  margin-right: 0 !important;
}

/**************************************************************
                 Migrated from button.scss
**************************************************************/
.page-btns {
  margin-top: 25px;
  padding-bottom: 75px;
  text-align: right;

  &.ui.button.tertiary.primary {
    margin-right: 30px !important;
  }

  .print {
    float: left;
    opacity: 1 !important;
  }
}

a.ui.button {
  width: fit-content; /* By default an `<a>` will want to fit 100% of the available width. */
}

/* Log in page Sign In button */
.sign-in {
  padding: 12px 88px !important;
}

/* Models Spreads (migrated from .scss file) */
.label-row-icon i {
  @apply text-neutral-600;
}

.label-row-icon .collapse:hover,
.label-row-icon .collapse:focus {
  border: 0;
}

.spreads-toggle-button {
  float: right;
  padding: 10px;
}

.spreads-control-button {
  float: right;
  margin: 5px;
}

.label-row-icon .ui.icon.button.right-margin {
  margin-right: 4px;
}

.label-row-icon button {
  height: 100% !important;
}
