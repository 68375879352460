.ui.label {
  font-weight: normal;
  white-space: nowrap; /*  table-with-labels */
  border: 0;
}

/* Information */
.ui.label.gray,
.ui.label.basic {
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-600);
}

/* Status / Information */
.ui.label.blue,
.ui.label.primary {
  background-color: var(--color-cyan-100) !important;
  color: var(--color-cyan-600) !important;
}

/* Light Blue */
.ui.label.light-blue {
  background-color: var(--color-slate-300);
  color: var(--color-slate-50);
}

/* Warning */
.ui.label.yellow {
  background-color: var(--color-yellow-100);
  color: var(--color-yellow-600);
}

/* Positive / Success */
.ui.label.green,
.ui.label.teal {
  background-color: var(--color-teal-100);
  color: var(--color-teal-600);
}

/* Negative / Error */
.ui.label.red {
  background-color: var(--color-red-100);
  color: var(--color-red-600)
}

/* Dark */
.ui.label.black {
  background-color: var(--color-neutral-700);
  color: var(--color-neutral-100)
}
