/* Remove spin buttons in gecko based browsers. */
.cm-date-picker input {
  -moz-appearance: textfield;
}

/* Remove spin buttons in webkit based browsers. */
.cm-date-picker input::-webkit-outer-spin-button,
.cm-date-picker input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
