.page-region .label {
  align-self: center;
}

.page-region .page-region-controls {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.page-region .page-region-titles {
  margin-right: 10px;
}

.page-region .page-region-titles .page-region-title {
  margin-bottom: 6px;
}

.page-region .page-region-titles .page-region-subtitle {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}
