.icon-button-container.one-third {
  right: 33.33%;
}

.icon-button-container.two-third {
  right: 66.66%;
}

.icon-button-container.half {
  right: 50%;
}

.icon-button-container.full {
  /* Saving some space for close icon */
  right: 98%;
}

.sidebar-container {
  padding: 21px 24px;
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgb(56 56 56 / 25%), 0 2px 4px 0 rgb(56 56 56 / 12%),
    0 0 0 1px var(--color-neutral-300) !important;
}

.sidebar-container .area {
  margin-right: -100%;
  margin-left: -100%;
}

.overlayed-content-container {
  background-color: rgba(23 35 58 / 60%) !important;
}
