.EntityAnalysisTable-AddExisting .menu > .item.item-custom {
  padding: 1rem !important;
  height: unset !important;
}

.EntityAnalysisTable-AddExisting .menu > .header {
  margin: 1rem 0 !important;
}

.EntityAnalysisTable-AddExisting.floating.dropdown .menu {
  left: auto;
  right: 0;
}

.search-list-dropdown {
  background-color: black !important;
}