.engageBAFS .modalLogo {
    margin: 0 auto;
    background-color: #ededed;
    padding: 5px;
    border-radius: 5px;
    width: 110px;
    height: 110px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.engageBAFS .modalLogo img.ui.image {
    max-width: 100px;
    max-height: 100px;
}

.engageBAFS .ui.modal p.error {
    color: red;
    font-weight: bold;
}

.engageBAFS .ui.form .fields.no-flex {
    display: block;
}

.engageBAFS .ui.form .fields.no-flex .ui.checkbox {
    margin-top: 1rem !important;
}

.engageBAFS .ui.message.red {
    color: red;
    background-color: #ffecec;
}