.ui.form .field > label {
  color: var(--color-slate-950); /* #050e1a; */
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 0.28571429rem;
  text-transform: none;
}


.dropdown.disabled {
  cursor: not-allowed !important;
}

