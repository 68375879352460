@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    font-family: "Open Sans", "Arial", system-ui, sans-serif;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-width: 320px;
    background: white;
  }
}

@layer components {
  .icon.outline {
    outline: none;
  }
}

html {
  @apply text-base;
}

i.icon,
i.icons {
  @apply text-sm;
}

label,
.label {
  @apply text-xs !important;
}

input {
  @apply text-sm !important;
}

/* iPad Input Shadows */
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* mobile firefox too! */
}

textarea {
  @apply p-3 w-full block border border-neutral-300 text-neutral-900 text-sm rounded focus:ring-cyan-500 focus:border-cyan-500 !important;
}

input[type="text"],
input[type="email"],
input[type="search"] {
  @apply py-2 pr-3 pl-2 border border-neutral-300 text-neutral-900 text-sm rounded focus:ring-cyan-500 focus:border-cyan-500;
}

select {
  @apply text-sm !important;
}

button {
  @apply text-sm !important;
}

table {
  @apply text-sm !important;
}

/* (width:1920 x height:1080) */