* {
  box-sizing: border-box !important;
}

.ui.view {
  padding: var(--padding-large);
}

.w-100 {
  width: 100%;
}

.fs-15 {
  font-size: 15px;
}
