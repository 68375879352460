.main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45vw, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  margin: 1rem;
}

.overviewcard {
  padding: 1rem;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(56, 56, 56, 0.12), 0px 2px 10px rgba(56, 56, 56, 0.15);
  border-radius: 8px;
  min-width: 400px;
  max-width: inherit;
  min-height: 400px;
  max-height: calc(50vh - 73px);
}

.overviewcard h3 {
  font-size: 12pt;
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 20px;
  line-height: 20px;
}

.overviewcard .chart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 20px);
  overflow: hidden;
}

.overviewcard.two {
  grid-column: span 2;
}

.overviewcard.three {
  grid-column: span 3;
}

.overviewcard.four {
  grid-column: span 4;
}

.grid-container {
  box-shadow: 1px 0px 4px rgba(0, 0, 0, .2) inset;
}

.grid-container .loader {
  color: #aaa;
}

.grid-container .notice {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2rem;
  color: white;
  font-weight: 600;
  text-align: center;
}

.grid-container .notice .wrapper {
  max-width: 1024px;
}

.grid-container div.jr {
  margin: 0 auto;
}

@media only screen and (min-width: 1450px) {
  .main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30vw, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  margin: 1rem;
  }
}