.sidebar-content-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.sidebar-content-container iframe {
  border: none;
}
.sidebar-content-container > .segment.vertical.corrections-side-sheet-title {
  padding-top: 0;
  flex-shrink: 1;
}
.sidebar-content-container > .segment.vertical.corrections-side-sheet-iframe {
  flex-grow : 1;
}