.dot-wave {
	position:relative;

	span {
		display:inline-block;
		width:6px;
		height:6px;
		border-radius:50%;
		margin-right:3px;
		background:#303131;
		animation: wave 1.3s linear infinite;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-15px);
	}
}
