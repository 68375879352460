.notifications-sidebar .ui.list .list {
  padding: 0;
}

.notifications-sidebar .list {
  padding: 0.75em 0 0.25em 0;
}

.notifications-sidebar .ui.list .list > .item {
  color: black;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 0.28571429rem;
  margin-bottom: 5px;
  padding: 0.3rem 0.5rem;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, .2);
}

.notifications-sidebar .ui.list .list > .item:hover  {
  background-color: #F0F5F9;;
}

.notifications-sidebar .ui.list .list > .item.unread {
  border-left: 3px solid #638AB5;
  color: #334663;
  font-weight: bold;
  background-color: #F0F5F9;
}

.notifications-sidebar .ui.list .list > .item.read  {
  color: black;
}

.notifications-sidebar .ui.list .header {
  width: 100%;
  padding: 3px 5px;
  border-bottom: 1px solid rgb(222 222 222);
  margin: 5px 0 1rem 0;
  font-weight: bold;
  color: #334663;
}
