button.validate-auto-spread {
    height: 28px;
    width: 28px;
    border-radius: 100%;
    padding: 0;
    background-color: #fff;
    color: #01a49c;
    border: 1px solid rgba(34, 36, 38, 0.15);
    margin-left: 10px;
    cursor: pointer;
}
.validate-auto-spread i {
    margin: 0 !important;
}
