.Toastify__toast-container {
  width: 472px !important;
}

.Toastify__toast {
  border: 1px solid rgba(34, 36, 38, 0.12) !important;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
}

/* Error */

.Toastify__toast.Toastify__toast--error {
  background-color: var(--color-red-50) !important;
  border: 1px solid var(--color-red-600) !important;
}

.Toastify__toast.Toastify__toast--error .Toastify__toast-icon svg {
  fill: var(--color-red-700) !important;
}

.Toastify__toast.Toastify__toast--error .ui.header,
.Toastify__toast.Toastify__toast--error .message {
  color: var(--color-red-700) !important;
}

/* Info */

.Toastify__toast.Toastify__toast--info {
  background-color: var(--color-cyan-100) !important;
  border: 1px solid var(--color-cyan-600) !important;
}

.Toastify__toast.Toastify__toast--info .Toastify__toast-icon svg {
  fill: var(--color-cyan-600) !important;
}

.Toastify__toast.Toastify__toast--info .ui.header,
.Toastify__toast.Toastify__toast--info .message {
  color: var(--color-cyan-600) !important;
}

/* Success */

.Toastify__toast.Toastify__toast--success {
  background-color: var(--color-teal-100) !important;
  border: 1px solid var(--color-teal-600) !important;
}

.Toastify__toast.Toastify__toast--success .Toastify__toast-icon svg {
  fill: var(--color-teal-700) !important;
}

.Toastify__toast.Toastify__toast--success .ui.header,
.Toastify__toast.Toastify__toast--success .message {
  color: var(--color-teal-700) !important;
}

/* Warning */

.Toastify__toast.Toastify__toast--warning {
  background-color: var(--color-yellow-100) !important;
  border: 1px solid var(--color-yellow-600) !important;
}

.Toastify__toast.Toastify__toast--warning .Toastify__toast-icon svg {
  fill: var(--color-yellow-900) !important;
}

.Toastify__toast.Toastify__toast--warning .ui.header,
.Toastify__toast.Toastify__toast--warning .message {
  color: var(--color-yellow-900) !important;
}

.toast-box {
  box-shadow: none !important;
  padding: 0 !important;
}

.Toastify__toast .ui.message {
  background: transparent !important;
  box-shadow: none !important;
}

.Toastify__toast .ui.header {
  margin-bottom: 4px;
}