.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: initial;
  animation: none;
  overflow: visible;
  padding: 1em 1em;
  min-height: 18rem;
  background: #F9FAFB;
  border-color: #dedede;
  box-shadow: 0 2px 25px 0 rgba(34, 36, 38, 0.05) inset;
}

.panel.dashed-rectangle {
  max-width: 100%;
  padding: 0.6px 0 0.4px 0;
  border-radius: 4px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border: 2px dashed #dedede;
  background-color: #fff;
}
